<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar class="px-2">
        <ion-label slot="start" class="fs-3 fw-bold">{{ $t('filter') }}</ion-label>
        <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="cancelApplyFilter"></ion-icon>
      </ion-toolbar>
      <div class="grey-lines" />
    </ion-header>
    <ion-content class="ion-padding">
      <div class="tb">{{ $t('sort') }}</div>
      <ion-radio-group :value="selectedSortId" mode="md">
        <ion-item
          @click="selectedSortRelevantNewest(isActivatedRelevant)"
          class="ion-no-padding sort-item"
          style="margin-right: -4vw"
          lines="none"
        >
          <div slot="start" class="sort-text">{{ $t('relevant') }}</div>
          <ion-radio
            slot="end"
            :value="isActivatedRelevant.id"
            @ionChange="selectedSortRelevantNewest(isActivatedRelevant)"
          ></ion-radio>
        </ion-item>
        <ion-item
          @click="selectedSortRelevantNewest(isActivatedNewest)"
          class="ion-no-padding sort-item"
          style="margin-right: -4vw"
          lines="none"
        >
          <div slot="start" class="sort-text">{{ $t('newest') }}</div>
          <ion-radio
            slot="end"
            :value="isActivatedNewest.id"
            @ionChange="selectedSortRelevantNewest(isActivatedNewest)"
          ></ion-radio>
        </ion-item>
      </ion-radio-group>
      <!-- Hide with price for  current release v1.0.0 -->
      <!-- <div class="grey-lines mt-3 mb-1" />
      <ion-item
        class="ion-no-padding sort-item"
        style="margin-right: -4vw"
        lines="none"
        @click="selectedWithPrice"
      >
        <div slot="start" class="sort-text">{{ $t('with_price') }}</div>
        <ion-checkbox slot="end" :checked="isActivatedWithPrice" mode="md"></ion-checkbox>
      </ion-item> -->
      <!-- Hide favorite for  current release v1.0.0 -->
      <!-- <ion-item
        class="ion-no-padding sort-item"
        style="margin-right: -4vw"
        lines="none"
        @click="selectedFavorite"
      >
        <div slot="start" class="sort-text">{{ $t('favorites') }}</div>
        <ion-checkbox slot="end" :checked="isActivatedFavorites.isActive" mode="md"></ion-checkbox>
      </ion-item> -->
      <div class="grey-lines mt-3 mb-1" />
      <div class="my-3">
        <ion-label class="tb">
          {{ $t('product_category') }}
        </ion-label>
        <div v-if="isLoadCategory" class="my-3">
          <ion-row class="mt-2">
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 20vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 24vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 30vw; height: 44px"
            ></ion-skeleton-text>

            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 20vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 36vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 18vw; height: 44px"
            ></ion-skeleton-text>

            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 32vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 28vw; height: 44px"
            ></ion-skeleton-text>

            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 24vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 24vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 22vw; height: 44px"
            ></ion-skeleton-text>

            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 24vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 20vw; height: 44px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 30vw; height: 44px"
            ></ion-skeleton-text>
          </ion-row>
        </div>
        <div v-else class="mt-2">
          <ion-grid>
            <ion-row>
              <div
                @click="selectedAll"
                class="px-2 my-1"
                :class="isAllCategoryActivated.isActive ? 'product-cate-selected' : 'product-cate'"
              >
                <ion-text class="mx-2">{{ $t('all') }}</ion-text>
              </div>
              <div
                v-for="cate in listDataProductCategory"
                :key="cate.id"
                @click="selectCategory(cate)"
                :class="[
                  'px-2',
                  'my-1',
                  {
                    'product-cate-selected': selectedCategoryIds.includes(cate.id),
                    'product-cate': !selectedCategoryIds.includes(cate.id)
                  }
                ]"
              >
                <ion-img class="cate-img" :src="categoryPhoto[cate.name]"></ion-img>
                <ion-text class="ml-2">{{ cate.name }}</ion-text>
              </div>
            </ion-row>
          </ion-grid>
        </div>
      </div>
      <div class="mb-2">
        <div v-if="loadAttributes" class="my-3">
          <ion-skeleton-text class="mx-1 my-2" animated style="width: 30vw; height: 16px"></ion-skeleton-text>
          <ion-row class="mt-2">
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 20vw; height: 28px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 24vw; height: 28px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 30vw; height: 28px"
            ></ion-skeleton-text>
          </ion-row>
          <ion-skeleton-text class="mx-1 my-2" animated style="width: 20vw; height: 16px"></ion-skeleton-text>
          <ion-row class="mt-2">
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 20vw; height: 28px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 20vw; height: 28px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 26vw; height: 28px"
            ></ion-skeleton-text>
          </ion-row>
          <ion-skeleton-text class="mx-1 my-2" animated style="width: 14vw; height: 16px"></ion-skeleton-text>
          <ion-row class="mt-2">
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 20vw; height: 28px"
            ></ion-skeleton-text>
            <ion-skeleton-text
              class="mx-1 my-2"
              animated
              style="width: 15vw; height: 28px"
            ></ion-skeleton-text>
          </ion-row>
        </div>
        <div v-else>
          <ion-grid class="ion-no-padding" v-for="(attributes, index) in filterAttributesB2b" :key="index">
            <ion-row class="mt-2" v-if="attributes.ProductAttributeValue.length > 0">
              <ion-col size="12">
                <div class="d-flex flex-row align-center">
                  <ion-label class="tb mr-1">{{ attributes.description }}</ion-label>
                  <ion-icon
                    v-if="attributes.key === 'glazing'"
                    @click="setOpenAttributesInfo(true)"
                    :icon="informationCircleOutline"
                    class="info-icon"
                  ></ion-icon>
                </div>
                <ion-toast
                  :is-open="isOpenAttributesInfo"
                  :duration="3000"
                  :icon="informationCircleOutline"
                  :message="$t('glazing_desc')"
                  mode="ios"
                  color="dark"
                  position="middle"
                  :buttons="okButtons"
                  @didDismiss="setOpenAttributesInfo(false)"
                ></ion-toast>
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <div
                      class="my-1"
                      v-for="(value, valueIndex) in attributes.ProductAttributeValue"
                      :key="valueIndex"
                    >
                      <div
                        @click="selectAttributes(attributes.key, value.value, attributes.id)"
                        class="mr-2 px-2 py-1"
                        :class="
                          isAttributesSelected(attributes.key, value.value)
                            ? 'attribute-item-selected'
                            : 'attribute-item'
                        "
                      >
                        {{ value.value }}
                      </div>
                    </div>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <ion-toolbar class="mb-3 px-2" style="--background: transparent">
        <ion-button
          class="ion-margin-top foot-btn"
          expand="block"
          @click="applyFilter"
          :disabled="totalFilterResult < 1"
        >
          <ion-spinner
            v-if="totalFilterResult === null || loadTotalFilterCount"
            name="dots"
            class="spinner-dots"
          ></ion-spinner>
          <ion-text v-else
            >{{ $t('apply_search_filter') }}
            {{ ' (' + totalFilterResult + ' ' + $t('products_result') + ')' }}</ion-text
          >
        </ion-button>
        <ion-button
          class="ion-margin-top"
          :class="filterData.totalSelectedFilter < 1 ? 'foot-btn-outline-disable' : 'foot-btn-outline'"
          expand="block"
          fill="outline"
          :disabled="filterData.totalSelectedFilter < 1"
          @click="resetFilter"
          >{{ $t('reset_search_filter') }} <ion-icon slot="end" :icon="closeOutline"></ion-icon
        ></ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { getProducts } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/b2b/store/category/actions';
import { ACTIONS as ACTIONS_GET_FILTER } from '@/modules/b2b/store/product/actions';
import Clevertap from '@/services/shared/helper/clevertap';
import { getImage } from '@/utils/';
import { toastController } from '@ionic/vue';
import { closeOutline, informationCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/category');
const {
  mapGetters: mapGettersFilterAttributes,
  mapActions: mapActionsFilterAttributes
} = createNamespacedHelpers('b2b/product');

export default defineComponent({
  props: {
    previousFilterData: {
      type: Object,
      default: () => {}
    },
    filterAttributesParam: {
      type: Object,
      default: () => {}
    },
    search: {
      type: Object,
      default: () => {}
    }
  },

  setup() {
    const isOpenAttributesInfo = ref(false);
    const setOpenAttributesInfo = (state) => {
      isOpenAttributesInfo.value = state;
    };
    const okButtons = [
      {
        text: 'OK',
        role: 'cancel'
      }
    ];
    const loadTotalFilterCount = ref(true);
    const loadAttributes = ref(true);
    const totalFilterResult = ref(null);
    const selectedAttributes = ref([]);
    const openToast = async (message) => {
      const toast = await toastController.create({
        mode: 'ios',
        color: 'danger',
        position: 'top',
        message: message,
        duration: 3000
      });
      return toast.present();
    };

    return {
      isOpenAttributesInfo,
      setOpenAttributesInfo,
      okButtons,
      closeOutline,
      informationCircleOutline,
      loadTotalFilterCount,
      loadAttributes,
      totalFilterResult,
      selectedAttributes,
      openToast
    };
  },

  computed: {
    ...mapGetters(['list', 'loading']),
    ...mapGettersFilterAttributes(['filterAttributesB2b']),
    selectedCategoriesParams() {
      return this.filterData.categoriesSelected;
    }
  },

  data() {
    return {
      isActivatedWithPrice: false,
      isActivatedRelevant: {
        id: 0
      },
      isActivatedNewest: {
        id: 1
      },
      isActivatedFavorites: {
        isActive: false
      },
      isAllCategoryActivated: {
        isActive: false
      },
      categoryPhoto: {},
      listDataProductCategory: [],
      firstFilterOption: {
        selectedCategories: [],
        isWithPrice: false,
        isFavorite: false,
        isAll: false,
        sordId: 0,
        productAttributeValues: []
      },
      selectedCategoryIds: [],
      selectedSortId: 0,
      previousSelectedSortId: null,
      combineCategoryFilter: [],
      isApplyFilter: false,
      filterData: {
        sort: 'most_relevant',
        sortId: 0,
        isWithPrice: false,
        isFavorite: false,
        isAll: false,
        categoriesSelected: [],
        totalSelectedFilter: 0,
        productAttributeValues: []
      },
      isLoadCategory: true
    };
  },

  async mounted() {
    await this[ACTIONS.GET_CATEGORIES]();
    for (const cat of this.list) {
      this.categoryPhoto[cat.name] = await getImage(cat.name, IMAGE_PATH.CATEGORY_ICON);
    }
    this.listDataProductCategory = [...this.list];
    if (this.listDataProductCategory?.length > 0) {
      this.isLoadCategory = false;
    }
    this.loadAttributes = true;
    await this[ACTIONS_GET_FILTER.GET_FILTER_ATTRIBUTES](this.filterAttributesParam);
    this.loadAttributes = false;
    if (this.previousFilterData !== null) {
      this.firstFilterOption.selectedCategories = this.previousFilterData.categoriesSelected;
      this.firstFilterOption.isAll = this.previousFilterData.isAll;
      this.firstFilterOption.isFavorite = this.previousFilterData.isFavorite;
      this.firstFilterOption.isWithPrice = this.previousFilterData.isWithPrice;
      this.firstFilterOption.sortId = this.previousFilterData.sortId;
      this.firstFilterOption.productAttributeValues = this.previousFilterData.productAttributeValues;
      this.selectedSortId = this.previousFilterData.sortId;
      this.isActivatedWithPrice = this.previousFilterData.isWithPrice;
      this.isActivatedFavorites.isActive = this.previousFilterData.isFavorite;
      this.isAllCategoryActivated.isActive = this.previousFilterData.isAll;
      this.filterData = this.previousFilterData;
      if (this.previousFilterData.categoriesSelected?.length > 0) {
        this.selectedCategoryIds = [...this.previousFilterData.categoriesSelected];
      }
      if (this.previousFilterData.productAttributeValues?.length > 0) {
        this.selectedAttributes = [...this.previousFilterData.productAttributeValues];
      }
      this.countSelectedFilter();
    }
    await this.getFiterCountResult();
  },

  emits: ['closeModal', 'applyFilterData'],

  methods: {
    ...mapActions([ACTIONS.GET_CATEGORIES]),
    ...mapActionsFilterAttributes([ACTIONS_GET_FILTER.GET_FILTER_ATTRIBUTES]),
    async selectedSortRelevantNewest(sortId) {
      this.selectedSortId = sortId.id;
      this.filterData.sort = this.selectedSortId === 0 ? 'most_relevant' : 'newest';
      this.filterData.sortId = this.selectedSortId;
      const filterCountParams = {
        ...this.filterAttributesParam
      };
      filterCountParams.mainCategoryId = this.selectedSortId === 0 ? 'history' : 'all';
      filterCountParams.sortBy = this.filterData.sort;
      this.loadAttributes = true;
      await this[ACTIONS_GET_FILTER.GET_FILTER_ATTRIBUTES](filterCountParams);
      this.loadAttributes = false;
      this.countSelectedFilter();
      Clevertap.onUserAppliedQuickSort(this.filterData.sort);
    },
    selectedAll() {
      if (!this.isAllCategoryActivated.isActive) {
        this.selectedCategoryIds = [];
        this.isAllCategoryActivated.isActive = true;
        this.filterData.isAll = this.isAllCategoryActivated.isActive;
        this.filterData.categoriesSelected = [];
        this.countSelectedFilter();
      }
    },
    selectCategory(cate) {
      this.isAllCategoryActivated.isActive = false;
      this.filterData.isAll = false;
      const index = this.selectedCategoryIds?.indexOf(cate.id);
      if (index > -1) {
        this.selectedCategoryIds?.splice(index, 1);
      } else {
        this.selectedCategoryIds?.push(cate.id);
      }
      this.filterData.categoriesSelected = this.selectedCategoryIds;
      this.countSelectedFilter();
      Clevertap.onUserAppliedQuickFilter(cate.name);
    },
    selectedWithPrice() {
      this.isActivatedWithPrice = !this.isActivatedWithPrice;
      this.filterData.isWithPrice = this.isActivatedWithPrice;
      this.countSelectedFilter();
    },
    selectedFavorite() {
      this.isActivatedFavorites.isActive = !this.isActivatedFavorites.isActive;
      this.filterData.isFavorite = this.isActivatedFavorites.isActive;
      this.countSelectedFilter();
    },
    async countSelectedFilter() {
      let totalFilterSelected = 0;
      if (this.filterData.categoriesSelected) {
        totalFilterSelected = this.filterData.categoriesSelected.length + totalFilterSelected;
      }

      if (this.filterData.isFavorite) {
        totalFilterSelected++;
      }
      if (this.filterData.isWithPrice) {
        totalFilterSelected++;
      }

      const selectedValues = this.selectedAttributes.reduce((result, category) => {
        return result.concat(category.values);
      }, []);

      this.filterData.totalSelectedFilter = this.selectedAttributes
        ? totalFilterSelected + selectedValues?.length
        : totalFilterSelected;
      if (this.filterData.categoriesSelected.length <= 0) {
        this.selectedAll();
      }
      await this.getFiterCountResult();
    },
    applyFilter() {
      this.isApplyFilter = true;
      this.$emit('applyFilterData', this.filterData);
      this.closeModal();
      this.isApplyFilter = false;
      this.onUserAppliedFilterOrSortEvent();
    },
    async getFiterCountResult() {
      this.loadTotalFilterCount = true;
      const selectedValues = this.selectedAttributes.reduce((result, category) => {
        return result.concat(category.values);
      }, []);
      const filterCountParams = {
        ...this.search
      };
      filterCountParams.mainCategoryId = 'all';
      filterCountParams.productAttributeValues = selectedValues;
      filterCountParams.productAttributes = this.selectedAttributes.filter(
        (attribute) => attribute.values.length
      );
      filterCountParams.categories = this.selectedCategoryIds;
      try {
        const { data, errors } = await apolloClient.query({
          query: getProducts,
          variables: filterCountParams
        });
        if (!errors) {
          this.totalFilterResult = data.getProductsB2B.totalCount;
        } else {
          this.openToast(errors[0]);
        }
      } catch (error) {
        this.openToast(error);
      } finally {
        this.loadTotalFilterCount = false;
      }
    },
    async selectAttributes(attributeKey, attributeValue, attributeId) {
      const keyIndex = this.selectedAttributes.findIndex((item) => item.key === attributeKey);

      if (keyIndex === -1) {
        // If the key is not in selectedCategories, add it
        this.selectedAttributes.push({ id: attributeId, key: attributeKey, values: [attributeValue] });
      } else {
        // If the key is already in selectedCategories, toggle the value
        const valueIndex = this.selectedAttributes[keyIndex].values.indexOf(attributeValue);

        if (valueIndex === -1) {
          this.selectedAttributes[keyIndex].values.push(attributeValue);
        } else {
          this.selectedAttributes[keyIndex].values.splice(valueIndex, 1);
        }
      }
      this.filterData.productAttributeValues = this.selectedAttributes;
      this.countSelectedFilter();
    },

    isAttributesSelected(key, value) {
      const selectedKey = this.selectedAttributes.find((item) => item.key === key);
      return selectedKey && selectedKey.values.includes(value);
    },

    onUserAppliedFilterOrSortEvent() {
      const isPickCategory = this.filterData.categoriesSelected.length > 0;
      const selectedFilters = this.listDataProductCategory.reduce((acc, curr) => {
        if (isPickCategory) {
          if (this.filterData.categoriesSelected.includes(curr.id)) {
            acc.push(curr.name);
          }
          return acc;
        } else {
          return null;
        }
      }, []);
      const filterEvents = {
        sort: this.filterData.sort,
        filters: selectedFilters
      };
      Clevertap.onUserAppliedFilterOrSort(filterEvents);
    },
    async resetFilter() {
      this.selectedSortId = 0;
      this.isActivatedWithPrice = false;
      this.isActivatedFavorites.isActive = false;
      this.isAllCategoryActivated.isActive = true;
      this.selectedCategoryIds = [];
      this.selectedAttributes = [];
      this.filterData.sort = 'most_relevant';
      this.filterData.sortId = 0;
      this.filterData.isWithPrice = false;
      this.filterData.isFavorite = false;
      this.filterData.isAll = true;
      this.filterData.categoriesSelected = [];
      this.filterData.totalSelectedFilter = 0;
      this.filterData.productAttributeValues = [];
      await this.getFiterCountResult();
      Clevertap.recordEventWithName('User reset filter');
    },
    cancelApplyFilter() {
      this.selectedCategoryIds = this.firstFilterOption.selectedCategories;
      this.selectedSortId = this.firstFilterOption.sordId;
      this.isActivatedWithPrice = this.firstFilterOption.isWithPrice;
      this.isActivatedFavorites.isActive = this.firstFilterOption.isFavorite;
      this.isAllCategoryActivated.isActive = this.firstFilterOption.isAll;
      this.filterData.categoriesSelected = this.firstFilterOption.selectedCategories;
      this.filterData.sortId = this.firstFilterOption.sordId;
      this.filterData.isWithPrice = this.firstFilterOption.isWithPrice;
      this.filterData.isFavorite = this.firstFilterOption.isFavorite;
      this.filterData.isAll = this.firstFilterOption.isAll;
      this.filterData.productAttributeValues = this.firstFilterOption.productAttributeValues;
      this.closeModal();
    },
    closeModal() {
      this.$emit('closeModal');
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
